import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {BundleEntry} from "components/bundle-entry/BundleEntry";
import {HeaderFavorites} from "./components/header-search/HeaderFavorites";

const target = document.getElementById('header-favorites');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);

const isAnonymous = target?.dataset?.['isAnonymous'];


if (target) {
	ReactDOM.render(
		<BundleEntry
			bundleName="header-favorites"
			locale={localeCode}
			prefetchTranslations={[]}>
			<HeaderFavorites
				isAnonymous={isAnonymous === 'true'}
			/>
		</BundleEntry>,
		document.getElementById('header-favorites')
	);
}