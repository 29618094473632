import {ReactComponent as FavoritesIconGold} from "../../widgets/favorite-icons/favorites-icon-gold.svg";
import {ReactElement} from "react";
import {redirectTo} from "../../lib/utility";


export interface HeaderFavoritesProps {
	isAnonymous?: boolean
}

export function HeaderFavorites(props: HeaderFavoritesProps): ReactElement {

	return(
		<div className={"tw-mt-2 lg:tw-hidden"}
		 	onClick={() => props.isAnonymous ? redirectTo("/favorites") : redirectTo("/my-articles/favorites")}>
			<FavoritesIconGold/>
		</div>
	);
}